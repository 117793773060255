<template>
  <div class="tomarCita mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>
            Tomar cita
          </v-card-title>
          <v-card-text style="padding: 0px 16px !important">
            <v-row>
              <v-col cols="12" md="8" sm="6">
                <v-row no-gutters>
                  <v-col cols="12" md="12" style="padding: 0px 6px !important">
                    <v-btn
                      small
                      color="black"
                      dark
                      class="mb-4"
                      @click="dialog = true"
                      >Registrar Cliente</v-btn
                    >
                  </v-col>
                  <!-- Seleccion cliente -->
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    style="padding: 0px 6px !important"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-autocomplete
                          v-model="id"
                          v-on="on"
                          v-bind="attrs"
                          dense
                          outlined
                          rounded
                          :loading="isLoading"
                          item-value="id"
                          :items="lista"
                          no-filter
                          label="Selecciona un cliente"
                          ref="clientes"
                          hide-no-data
                          @update:search-input="customFilter"
                          @change="detailCliente()"
                        ></v-autocomplete>
                      </template>
                      <span>Filtra tus clientes por nombre o cedula / ID</span>
                    </v-tooltip>
                  </v-col>
                  <!-- Cambio de fecha -->
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    style="padding: 0px 6px !important"
                  >
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          outlined
                          rounded
                          dense
                          label="Fecha"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        scrollable
                        v-model="date"
                        locale="es-co"
                        color="primary"
                        :min="min"
                        first-day-of-week="1"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Listado de servicios -->
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    style="padding: 0px 6px !important"
                    v-if="cliente"
                  >
                    <v-autocomplete
                      dense
                      outlined
                      rounded
                      v-model="servicio"
                      :loading="isLoadingS"
                      append-icon="mdi-magnify"
                      item-text="servicio"
                      :items="servicios"
                      :filter="customFilterServicios"
                      label="Selecciona un servicio"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <!-- Duracion del servicio -->
                  <v-col
                    cols="12"
                    md="3"
                    sm="6"
                    style="padding: 0px 6px !important"
                    v-if="servicio"
                  >
                    <v-select
                      width="200"
                      outlined
                      rounded
                      dense
                      v-model="servicio.duracion"
                      color="primary"
                      :items="proceso"
                      item-color="primary"
                      item-text="hora"
                      item-value="minutos"
                      :menu-props="{ maxHeight: '400' }"
                      label="Duración"
                      persistent-hint
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    sm="6"
                    style="padding: 0px 6px !important"
                  >
                    <div align="center">
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="primary"
                              >mdi-checkbox-blank-circle-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Disponible</span>
                      </v-tooltip>
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="purple">mdi-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Ocupado</span>
                      </v-tooltip>
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="grey">mdi-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Bloqueado</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4 " sm="6">
                <!-- Detalle cliente seleccionado -->
                <v-card elevation="0" outlined v-if="cliente != null">
                  <v-card-text>
                    <v-row align="center" no-gutters>
                      <v-col>
                        <strong>
                          {{ cliente.nombre }}
                        </strong>
                        <br />
                        <strong> Cc: {{ cliente.cedula }} </strong>
                        <br />
                        <strong> Tel: {{ cliente.telefono }} </strong>
                        <br />
                        <strong>
                          {{ cliente.email }}
                        </strong>
                        <br />
                        <strong>
                          Direccion:
                          {{ cliente.direccion }}
                        </strong>
                        <br />
                        <div v-if="$route.params.cita != undefined">
                          <strong>Hora:</strong>
                          {{ $route.params.cita.inicio }}
                          <br />
                          <strong>Profesional:</strong>
                          {{ $route.params.cita.profesional.profesional }}
                        </div>
                      </v-col>
                      <v-col align="right">
                        <v-avatar>
                          <img
                            :src="
                              $store.state.centro.route +
                                'clientes/' +
                                cliente.foto
                            "
                          />
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="12"
                sm="12"
                v-if="cliente"
                style="padding: 0px 12px !important"
              >
                <agenda
                  :date="date"
                  :servicio="servicio"
                  :cliente="cliente"
                  v-show="servicio"
                />
              </v-col>
            </v-row>
            <v-dialog v-model="dialog" persistent scrollable max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Crear cliente</span>
                </v-card-title>
                <v-card-text
                  ><v-row>
                    <v-col cols="12" md="12" sm="12">
                      <formComponent :list="form" ref="clienteForm" />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="cancel()" color="primary">
                    Cancelar
                  </v-btn>
                  <v-btn
                    :dark="!loading"
                    :color="!loading ? 'black' : 'gray'"
                    :loading="loading"
                    :disabled="loading"
                    @click="registerCliente()"
                  >
                    Crear
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import agenda from "../../components/Agenda.vue";
import formComponent from "../../components/Form.vue";

import * as moment from "moment";
moment.locale("es");
export default {
  name: "tomarcita",
  components: {
    agenda,
    formComponent,
  },
  data: () => ({
    search: null,
    id: null,
    loading: false,
    isLoading: false,
    isLoadingS: false,
    cliente: null,
    clientes: [],
    servicios: [],
    servicio: null,
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    min: moment().format("YYYY-MM-DD"),
    dialog: false,
    form: {
      ref: "clientesRegister",
      list: [
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "ID / CC",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "cedula",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Cliente",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "nombre",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Correo",
          },
          validation: {
            valid: true,
            rules: "required|email",
          },
          id: "email",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Dirección",
          },
          validation: {
            valid: false,
            rules: "",
          },
          id: "direccion",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "Telefono",
          },
          validation: {
            valid: false,
            rules: "",
          },
          id: "telefono",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: "picker",
          menu: {
            model: false,
            label: "Cumpleaños",
          },
          validation: {
            valid: false,
          },
          id: "fecha",
          model: moment().format("YYYY-MM-DD"),
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: "select",
          select: {
            label: "Genero",
            items: [
              { text: "Masculino", value: "m" },
              { text: "Femenino", value: "f" },
              { text: "Otros", value: "o" },
            ],
            hint: "Selecciona un genero",
            multiple: false,
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "genero",
          model: "f",
          show: true,
        },
      ],
    },
  }),
  methods: {
    reagendar() {
      if (this.$route.params.cita != undefined) {
        this.id = this.$route.params.cita.cliente.id;
        this.date = this.$route.params.cita.date;
        this.detailCliente();
      }
    },
    loadClientesLite() {
      this.clientes = [];
      this.isLoading = true;
      const body = {
        route: `/clientes_lite`,
        params: {
          search: this.search,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    detailCliente() {
      const body = {
        route: "/detalle_cliente",
        params: {
          id: this.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.cliente = response.data.data;
          }
        })
        .finally(() => {
          this.id = null;
          this.$refs.clientes.reset();
          if (this.servicios.length == 0) this.loadServicios();
        });
    },
    loadServicios() {
      this.isLoadingS = true;
      const body = {
        route: "/servicios_activos",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.servicios = response.data.data;
            this.servicios = this.servicios.map((servicio) => {
              servicio.duracion = parseInt(servicio.duracion);
              return servicio;
            });
            if (this.$route.params.cita != undefined) {
              let index = this.servicios.findIndex(
                (item) =>
                  item.id_activo == this.$route.params.cita.servicio.id_activo
              );
              this.servicio = this.servicios[index];
            }
          }
        })
        .finally(() => (this.isLoadingS = false));
    },
    customFilter(item) {
      this.search = item;
      if (this.search != null || this.search != undefined) {
        if (this.search != "") this.loadClientesLite();
      }
    },
    customFilterServicios(item, queryText, itemText) {
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      const text = removeAccents(queryText);
      const textOne = item.servicio.toLowerCase();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    registerCliente() {
      this.$refs.clienteForm.validate().then((res) => {
        if (res) {
          this.loading = true;
          let body = {
            route: "/register_cliente",
            data: {
              data: this.$store.getters.validation_form(this.form.list),
            },
          };
          body.data.data.telefono =
            body.data.data.telefono == "" ? "0000000" : body.data.data.telefono;
          body.data.data.direccion =
            body.data.data.direccion == ""
              ? "No registra"
              : body.data.data.direccion;
          this.$store
            .dispatch("axios_post", body)
            .then((response) => {
              if (response.data.success) {
                let alert = {
                  alert: true,
                  color: "success",
                  text: response.data.data,
                };
                this.$store.dispatch("setAlert", alert);
                this.cancel();
              }
            })
            .catch((error) => {
              this.loading = false;
              let alert = {
                alert: true,
                color: "error",
                text: error.response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
            });
        }
      });
    },
    cancel() {
      this.loading = false;
      this.dialog = false;
      this.setForm();
      this.$refs.clienteForm.reset();
    },
    setForm() {
      this.form.list.forEach((item) => {
        if (item.id == "puntos" || item.id == "estado") {
          item.show = false;
        }
        if (item.id != undefined) {
          item.model = "";
          if (item.id == "fecha") {
            item.model = moment().format("YYYY-MM-DD");
          }

          if (item.id == "genero") {
            item.model = "f";
          }
        }
      });
    },
  },
  created() {
    // this.loadClientesLite();
    // this.loadServicios();
    this.reagendar();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      return this.clientes.map((cliente) => {
        return {
          text: `${cliente.nombre} [${cliente.cedula}]`,
          nombre: cliente.nombre,
          cedula: cliente.cedula,
          id: cliente.id,
        };
      });
    },
    proceso() {
      function calculateRange(start, end, minutes) {
        let range = [];
        for (
          let hour = moment(start);
          hour.isBefore(end);
          hour.add(minutes, "minutes")
        ) {
          range.push(moment(hour));
        }
        range.push(moment(end));
        return range;
      }

      let start = moment("00:00", "HH:mm").add(
        parseInt(this.$store.state.centro.agenda),
        "minutes"
      );
      let end = moment("08:00", "HH:mm");
      let rangeArray = calculateRange(
        start,
        end,
        this.$store.state.centro.agenda
      ).map((hora) => {
        return {
          hora:
            parseInt(hora.format("HH")) * 60 + parseInt(hora.format("mm")) < 60
              ? hora.format("mm") + " Min"
              : hora.format("H:mm") + " Hr",
          minutos:
            parseInt(hora.format("HH")) * 60 + parseInt(hora.format("mm")),
        };
      });
      return rangeArray;
    },
  },
  watch: {
    id(val) {
      if (val != undefined || val != null) this.$refs.clientes.reset();
      return;
    },
  },
};
</script>
