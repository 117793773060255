<template>
  <div class="agenda">
    <v-row>
      <v-col sm="12" md="12" cols="12" v-if="apertura.inicio == null">
        <v-alert type="warning">
          El centro no cuenta con agenda para este dia
        </v-alert>
      </v-col>
      <v-col
        sm="12"
        md="12"
        cols="12"
        style="display: flex;
        overflow: hidden;
        overflow-x: scroll;"
        v-else
      >
        <!-- contenedor agendas -->
        <div class="container-agenda">
          <!-- contenedor profesionales -->
          <div
            class="header-prof scroll-p"
            id="scroll-p"
            @mousewheel="mouseEvent"
            @scroll="scrollEvent"
          >
            <v-col v-if="loading_a">
              <div class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-col>
            <v-col
              v-for="(profesional, index) in headerAgenda"
              :key="index"
              v-else
            >
              <div align="center">
                <div style="width: 100px;">
                  <v-avatar>
                    <img
                      :src="
                        $store.state.centro.route +
                          'profesionales/' +
                          profesional.foto
                      "
                    />
                  </v-avatar>
                  <br />
                  <strong>
                    {{ profesional.apodo }}
                  </strong>
                </div>
              </div>
            </v-col>
          </div>
          <!-- contenedor horas por profesional -->
          <div class="horas scroll-h" id="scroll-h">
            <v-col v-if="loading_a">
              <div class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-col>
            <v-col v-if="!loading_a && agendaFinal.length == 0">
              <h3 class="text-center">El servicio no se encuentra asignado a ningún profesional</h3>
            </v-col>
            <v-col v-for="(item, index) in agendaFinal" :key="index" v-else>
              <div
                align="center"
                class="mt-2"
                v-for="(hora, i) in item"
                :key="i"
              >
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  :ref="`menu_${index}_${i}`"
                  v-if="hora.estado === 0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      width="100px"
                      outlined
                      small
                      color="primary"
                      @click="profesionalSelected(hora.p_id)"
                    >
                      {{ hora.hora }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Cita agendada para:
                          </v-list-item-title>
                          <strong>
                            {{ moment(date).format("ll") }}
                          </strong>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Hora:
                          </v-list-item-title>
                          <strong>
                            {{ hora.hora }}
                          </strong>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Profesional:
                          </v-list-item-title>
                          <strong v-if="profesional != null">
                            {{ profesional.apodo }}
                          </strong>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Notas:
                          </v-list-item-title>
                          <v-textarea
                            no-resize
                            rows="5"
                            @input="saveNota"
                            :value="ant_nota"
                          >
                          </v-textarea>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-card-actions v-if="profesional != null">
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        text
                        color="primary"
                        :loading="loading"
                        :disabled="loading"
                        @click="tomarCita(hora, index, i, 'continuar')"
                        v-if="$route.params.cita == undefined"
                      >
                        Agendar y continuar
                      </v-btn>
                      <v-btn
                        small
                        :dark="!loading"
                        :color="!loading ? 'black' : 'gray'"
                        :loading="loading"
                        :disabled="loading"
                        @click="tomarCita(hora, index, i, 'agenda')"
                      >
                        Agendar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
                <v-btn
                  dark
                  width="100px"
                  small
                  color="purple"
                  v-if="hora.estado === 1"
                >
                  {{ hora.hora }}
                </v-btn>
                <v-btn
                  dark
                  width="100px"
                  small
                  color="grey"
                  v-if="hora.estado === 2"
                >
                  {{ hora.hora }}
                </v-btn>
              </div>
            </v-col>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "agenda",
  props: ["date", "servicio", "cliente"],
  data: () => ({
    loading: false,
    loading_a: false,
    horarios: {
      inicio_d: "",
      fin_d: "",
      inicio_s: "",
      fin_s: "",
      inicio_lv: "",
      fin_lv: "",
    },
    profesionales: [],
    servicios_prof: [],
    profesional: null,
    horas: {
      agenda: [],
      bloqueos: [],
    },
    cita: {
      cliente: "",
      inicio: "",
      fin: "",
      date: "",
      profesional: "",
      servicio: "",
      nota: "",
      horas: [],
    },
    ant_nota: "",
  }),
  methods: {
    loadProfesionales() {
      this.loading_a = true;
      const body = {
        route: "/profesional_servicios",
        params: {
          id: this.servicio.id_activo,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.profesionales = response.data.data;
          }
        })
        .finally(() => (this.loading_a = false));
    },
    loadHorarios() {
      const body = {
        route: "/sede",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.horarios = JSON.parse(response.data.data.horarios);
        }
      });
    },
    loadHoras() {
      const body = {
        route: "/tiempo_agendado",
        params: {
          date: this.date,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.horas = response.data.data;
          if (this.$route.params.cita != undefined) {
            const index = this.horas.agenda.findIndex(
              (element) => element.id == this.$route.params.cita.id
            );
            if (index != -1) this.horas.agenda.splice(index, 1);
            this.cita.nota = this.$route.params.cita.notas;
            this.ant_nota = this.$route.params.cita.notas;
          }
        }
      });
    },
    profesionalSelected(id) {
      let index = this.headerAgenda.findIndex((element) => element.id === id);
      this.profesional = this.headerAgenda[index];
    },
    saveNota(input) {
      this.cita.nota = input;
    },
    tomarCita(hora, index, i, type) {
      this.loading = true;
      this.cita.cliente = this.cliente.id;
      this.cita.inicio = moment(hora.hora, "h:mm A").format("HH:mm");
      this.cita.fin = moment(hora.hora, "h:mm A")
        .add(this.servicio.duracion, "minutes")
        .format("HH:mm");
      this.cita.date = this.date;
      this.cita.profesional = hora.p_id;
      this.cita.servicio = this.servicio.id_activo;
      this.cita.nota = this.cita.nota == "" ? null : this.cita.nota;
      this.cita.horas = this.iteracion(this.cita.inicio, this.cita.fin);
      let body = {
        route: "/tomar_cita",
        data: {
          data: this.cita,
        },
      };
      if (this.$route.params.cita != undefined) {
        body.route = "/reagendar_cita";
        Object.assign(body.data, { id: this.$route.params.cita.id });
      }
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.$refs[`menu_${index}_${i}`][0].isActive = false;
            if (type == "continuar") {
              this.loadHoras();
              this.cita.nota = "";
            } else {
              this.$router.push({
                name: "agenda",
                params: {
                  date: this.date,
                },
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
    moment(data) {
      return moment(data);
    },
    // Calcular tiempo de duracion del servicio por agendar
    iteracion(inicio, fin) {
      function calculateRange(start, end, minutes) {
        let range = [];
        for (
          let hour = moment(start);
          hour.isBefore(end);
          hour.add(minutes, "minutes")
        ) {
          range.push(moment(hour));
        }
        range.push(moment(end));
        return range;
      }
      let start = moment(inicio, "HH:mm");
      let end = moment(fin, "HH:mm").add(
        -this.$store.state.centro.agenda,
        "minutes"
      );
      let rangeArray = calculateRange(
        start,
        end,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return hora.format("HH:mm:ss");
      });
      return rangeArray;
    },
    mouseEvent(event) {
      let targetH = document.getElementsByClassName("scroll-h")[0];
      let HtoLeft = event.deltaY < 0 && targetH.scrollLeft > 0;
      let HtoRight =
        event.deltaY > 0 &&
        targetH.scrollLeft < targetH.scrollWidth - targetH.clientWidth;
      if (HtoLeft || HtoRight) {
        event.preventDefault();
        targetH.scrollLeft += event.deltaY;
      }

      let targetP = document.getElementsByClassName("scroll-p")[0];
      let PtoLeft = event.deltaY < 0 && targetP.scrollLeft > 0;
      let PtoRight =
        event.deltaY > 0 &&
        targetP.scrollLeft < targetP.scrollWidth - targetP.clientWidth;
      if (PtoLeft || PtoRight) {
        event.preventDefault();
        targetP.scrollLeft += event.deltaY;
      }
    },
    scrollEvent(event) {
      let timeStamp = event.timeStamp;
      let id = event.target.id;
      let target;
      if (id == "scroll-h") {
        target = document.getElementsByClassName("scroll-p")[0];
      } else {
        target = document.getElementsByClassName("scroll-h")[0];
      }
      event.preventDefault();
      target.scrollLeft = event.target.scrollLeft;
      target.timeStamp = timeStamp;
    },
  },
  created() {
    this.loadHorarios();
    this.loadHoras();
  },
  computed: {
    apertura() {
      let horarios = { inicio: null, fin: null };
      if (moment(this.date).isoWeekday() == 7) {
        horarios.inicio = this.horarios.inicio_d;
        horarios.fin = this.horarios.fin_d;
      }

      if (moment(this.date).isoWeekday() == 6) {
        horarios.inicio = this.horarios.inicio_s;
        horarios.fin = this.horarios.fin_s;
      }

      if (moment(this.date).isoWeekday() <= 5) {
        horarios.inicio = this.horarios.inicio_lv;
        horarios.fin = this.horarios.fin_lv;
      }
      return horarios;
    },
    // Generar agenda del profesional
    agenda() {
      function calculateRange(start, end, minutes) {
        let range = [];
        for (
          let hour = moment(start);
          hour.isBefore(end);
          hour.add(minutes, "minutes")
        ) {
          range.push(moment(hour));
        }
        range.push(moment(end));
        return range;
      }
      let actual = moment(moment().format("HH:mm"), "HH:mm");
      let start = moment(this.apertura.inicio, "HH:mm");

      if (this.date == moment().format("YYYY-MM-DD")) {
        if (actual.isSameOrBefore(moment(this.apertura.inicio, "HH:mm"))) {
          start = moment(this.apertura.inicio, "HH:mm");
        } else {
          let rounded =
            parseInt(this.$store.state.centro.agenda) -
            (actual.minute() % parseInt(this.$store.state.centro.agenda));
          let resul = moment(actual)
            .add(rounded, "minutes")
            .format("HH:mm");
          start = moment(resul, "HH:mm");
        }
      } else {
        start = moment(this.apertura.inicio, "HH:mm");
      }
      let end = moment(this.apertura.fin, "HH:mm");
      let rangeArray = calculateRange(
        start,
        end,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return {
          hora: hora.format("h:mm A"),
          estado: 0,
        };
      });

      return rangeArray;
    },
    profesionalesList() {
      const profesionales = this.profesionales.map((prof) => {
        const agenda = this.agenda.map((ag) => {
          return {
            hora: ag.hora,
            estado: 0,
            p_id: prof.id,
            date: this.date,
          };
        });
        return {
          profesional: prof.nombre,
          id: prof.id,
          foto: prof.foto,
          apodo: prof.apodo,
          agenda: agenda,
        };
      });
      return profesionales;
    },
    headerAgenda() {
      return this.profesionalesList.map((prof) => {
        return {
          profesional: prof.profesional,
          id: prof.id,
          foto: prof.foto,
          apodo: prof.apodo,
        };
      });
    },
    agendaFinal() {
      const agenda = this.profesionalesList.map((prof) => {
        this.horas.agenda
          .filter((hora) => {
            return hora.profesional_id == prof.id;
          })
          .forEach((hora) => {
            const inicio = moment(hora.inicio, "HH:mm:ss").format("HH:mm");
            const fin = moment(hora.fin, "HH:mm:ss").format("HH:mm");
            this.iteracion(inicio, fin).forEach((hr) => {
              prof.agenda.forEach((ag) => {
                if (
                  moment(hr, "HH:mm").format("h:mm A") === ag.hora &&
                  hora.fecha == ag.date
                ) {
                  ag.estado = 1;
                }
              });
            });
          });

        this.horas.bloqueos
          .filter((hora) => {
            return hora.profesional_id == prof.id;
          })
          .forEach((hora) => {
            prof.agenda.forEach((ag) => {
              if (
                moment(hora.hora, "HH:mm:ss").format("h:mm A") === ag.hora &&
                hora.fecha == ag.date
              ) {
                ag.estado = 2;
              }
            });
          });
        return prof.agenda;
      });
      return agenda;
    },
  },
  watch: {
    servicio(val) {
      this.loadProfesionales();
      return this.servicio;
    },
    date(val) {
      this.horas = {
        agenda: [],
        bloqueos: [],
      };
      this.loadHoras();
      return this.date;
    },
  },
};
</script>
