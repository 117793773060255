var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tomarCita mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Tomar cita ")]),_c('v-card-text',{staticStyle:{"padding":"0px 16px !important"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"padding":"0px 6px !important"},attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"mb-4",attrs:{"small":"","color":"black","dark":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Registrar Cliente")])],1),_c('v-col',{staticStyle:{"padding":"0px 6px !important"},attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._g(_vm._b({ref:"clientes",attrs:{"dense":"","outlined":"","rounded":"","loading":_vm.isLoading,"item-value":"id","items":_vm.lista,"no-filter":"","label":"Selecciona un cliente","hide-no-data":""},on:{"update:search-input":_vm.customFilter,"change":function($event){return _vm.detailCliente()}},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}},'v-autocomplete',attrs,false),on))]}}])},[_c('span',[_vm._v("Filtra tus clientes por nombre o cedula / ID")])])],1),_c('v-col',{staticStyle:{"padding":"0px 6px !important"},attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","min":_vm.min,"first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),(_vm.cliente)?_c('v-col',{staticStyle:{"padding":"0px 6px !important"},attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","rounded":"","loading":_vm.isLoadingS,"append-icon":"mdi-magnify","item-text":"servicio","items":_vm.servicios,"filter":_vm.customFilterServicios,"label":"Selecciona un servicio","return-object":""},model:{value:(_vm.servicio),callback:function ($$v) {_vm.servicio=$$v},expression:"servicio"}})],1):_vm._e(),(_vm.servicio)?_c('v-col',{staticStyle:{"padding":"0px 6px !important"},attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-select',{attrs:{"width":"200","outlined":"","rounded":"","dense":"","color":"primary","items":_vm.proceso,"item-color":"primary","item-text":"hora","item-value":"minutos","menu-props":{ maxHeight: '400' },"label":"Duración","persistent-hint":""},model:{value:(_vm.servicio.duracion),callback:function ($$v) {_vm.$set(_vm.servicio, "duracion", $$v)},expression:"servicio.duracion"}})],1):_vm._e(),_c('v-col',{staticStyle:{"padding":"0px 6px !important"},attrs:{"cols":"12","md":"3","sm":"6"}},[_c('div',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-blank-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Disponible")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-circle")])],1)]}}])},[_c('span',[_vm._v("Ocupado")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-circle")])],1)]}}])},[_c('span',[_vm._v("Bloqueado")])])],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4 ","sm":"6"}},[(_vm.cliente != null)?_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('strong',[_vm._v(" "+_vm._s(_vm.cliente.nombre)+" ")]),_c('br'),_c('strong',[_vm._v(" Cc: "+_vm._s(_vm.cliente.cedula)+" ")]),_c('br'),_c('strong',[_vm._v(" Tel: "+_vm._s(_vm.cliente.telefono)+" ")]),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.cliente.email)+" ")]),_c('br'),_c('strong',[_vm._v(" Direccion: "+_vm._s(_vm.cliente.direccion)+" ")]),_c('br'),(_vm.$route.params.cita != undefined)?_c('div',[_c('strong',[_vm._v("Hora:")]),_vm._v(" "+_vm._s(_vm.$route.params.cita.inicio)+" "),_c('br'),_c('strong',[_vm._v("Profesional:")]),_vm._v(" "+_vm._s(_vm.$route.params.cita.profesional.profesional)+" ")]):_vm._e()]),_c('v-col',{attrs:{"align":"right"}},[_c('v-avatar',[_c('img',{attrs:{"src":_vm.$store.state.centro.route +
                              'clientes/' +
                              _vm.cliente.foto}})])],1)],1)],1)],1):_vm._e()],1),(_vm.cliente)?_c('v-col',{staticStyle:{"padding":"0px 12px !important"},attrs:{"cols":"12","md":"12","sm":"12"}},[_c('agenda',{directives:[{name:"show",rawName:"v-show",value:(_vm.servicio),expression:"servicio"}],attrs:{"date":_vm.date,"servicio":_vm.servicio,"cliente":_vm.cliente}})],1):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Crear cliente")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('formComponent',{ref:"clienteForm",attrs:{"list":_vm.form}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"dark":!_vm.loading,"color":!_vm.loading ? 'black' : 'gray',"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.registerCliente()}}},[_vm._v(" Crear ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }